.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
    text-align: center;
}

.centered-content {
    display: flex;
    flex-direction: column; /* Display items in a column */
    align-items: center; /* Center items horizontally */
}

.note-container {
    margin-top: -50px; /* Adjust as needed for better centering */
}

.bottom-download-app {
    position: absolute;
    bottom: 10px; /* Stick it to the bottom */
}